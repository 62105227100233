const state = {
  shopById: {},
  shopByName: {},
  status: "",
  shopRefreshDate: 0,
};

export default {
  state,
};
