import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_ERROR,
  AUTH_LOGOUT,
  AUTH_REFRESH_ERROR,
  AUTH_REFRESH_SUCCESS,
} from "./enums";

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, { resp, tenant }) => {
    state.status = "success";
    state.accessToken = resp.data.accessToken;
    state.accessTokenExpiresAt = new Date(
      resp.data.accessTokenExpiresAt
    ).getTime();
    state.refreshToken = resp.data.refreshToken;
    state.refreshTokenExpiresAt = new Date(
      resp.data.refreshTokenExpiresAt
    ).getTime();
    state.scope = resp.data.scope;
    state.client.id = resp.data.client.id;
    state.client.clientId = resp.data.client.clientId;
    state.client.scope = resp.data.client.scope;
    state.user.id = resp.data.user.id;
    state.user.email = resp.data.user.email;
    state.user.fullname = resp.data.user.fullname;
    state.user.company = resp.data.user.company;
    state.user.shopIds = resp.data.user.shopIds;
    state.user.scope = resp.data.user.scope.split(" ");
    state.tenant = tenant;
  },
  [AUTH_REFRESH_SUCCESS]: (state, resp) => {
    state.status = "success";
    state.accessToken = resp.data.accessToken;
    state.accessTokenExpiresAt = new Date(resp.data.accessTokenExpiresAt);
  },
  [AUTH_REFRESH_ERROR]: (state) => {
    state.status = "error";
    state.accessToken = "";
    state.accessTokenExpiresAt = 0;
    state.refreshToken = "";
    state.refreshTokenExpiresAt = 0;
    state.scope = "";
    state.client.id = "";
    state.client.clientId = "";
    state.client.scope = "";
    state.user.id = "";
    state.user.email = "";
    state.user.fullname = "";
    state.user.company = "";
    state.user.shopIds = ["voluntarily not empty nor null"];
    state.user.scope = [];
  },
  [AUTH_ERROR]: (state) => {
    state.status = "error";
    state.accessToken = "";
    state.accessTokenExpiresAt = 0;
    state.refreshToken = "";
    state.refreshTokenExpiresAt = 0;
    state.scope = "";
    state.client.id = "";
    state.client.clientId = "";
    state.client.scope = "";
    state.user.id = "";
    state.user.email = "";
    state.user.fullname = "";
    state.user.company = "";
    state.user.shopIds = ["voluntarily not empty nor null"];
    state.user.scope = [];
  },
  [AUTH_LOGOUT]: (state) => {
    state.status = "";
    state.accessToken = "";
    state.accessTokenExpiresAt = new Date();
    state.refreshToken = "";
    state.refreshTokenExpiresAt = new Date();
    state.scope = "";
    state.client.id = "";
    state.client.clientId = "";
    state.client.scope = "";
    state.user.id = "";
    state.user.email = "";
    state.user.fullname = "";
    state.user.company = "";
    state.user.shopIds = ["voluntarily not empty nor null"];
    state.user.scope = [];
  },
};

export default {
  mutations,
};
