import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_ERROR,
  AUTH_LOGOUT,
  AUTH_REFRESH,
  AUTH_REFRESH_ERROR,
  AUTH_REFRESH_SUCCESS,
} from "./enums";
import { SHOPS_CLEAR } from "../shops/enums";
import Vue from "vue";
import axios from "axios";

let actions = {
  [AUTH_REQUEST]: ({ commit }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      const data = {
        grant_type: "password",
        client_id: process.env.VUE_APP_API_OAUTH_CLIENT_ID,
        client_secret: process.env.VUE_APP_API_OAUTH_CLIENT_SECRET,
        username: user.username,
        password: user.password,
        scope: process.env.VUE_APP_API_OAUTH_SCOPE,
      };
      const tenant = user.tenant;
      let formdata = Object.entries(data)
        .map(([k, v]) => `${k}=${v}`)
        .join("&");
      let url =
        Vue.prototype.$api.routes[process.env.VUE_APP_API_VERSION || "v1"]
          .login;
      axios({
        url: process.env.VUE_APP_API_BASE_URL + url,
        data: formdata,
        method: "POST",
      })
        .then((resp) => {
          localStorage.setItem("user-token", resp.data.accessToken);
          commit(AUTH_SUCCESS, { resp, tenant });
          resolve(resp);
        })
        .catch((err) => {
          commit(AUTH_ERROR, err);
          reject(err);
        });
    });
  },
  [AUTH_REFRESH]: ({ state, commit }) => {
    return new Promise((resolve, reject) => {
      commit("AUTH_REQUEST");
      const data = {
        grant_type: "refresh_token",
        client_id: process.env.VUE_APP_API_OAUTH_CLIENT_ID,
        client_secret: process.env.VUE_APP_API_OAUTH_CLIENT_SECRET,
        refresh_token: state.refreshToken,
      };
      let formdata = Object.entries(data)
        .map(([k, v]) => `${k}=${v}`)
        .join("&");
      let url =
        Vue.prototype.$api.routes[process.env.VUE_APP_API_VERSION || "v1"]
          .refresh;
      axios({
        url: process.env.VUE_APP_API_BASE_URL + url,
        data: formdata,
        method: "POST",
      })
        .then((resp) => {
          localStorage.setItem("user-token", resp.data.accessToken);
          commit(AUTH_REFRESH_SUCCESS, resp);
          resolve(resp);
        })
        .catch((err) => {
          commit(AUTH_REFRESH_ERROR, err);
          reject(err);
        });
    });
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise((resolve) => {
      localStorage.removeItem("user-token");
      commit(AUTH_LOGOUT);
      commit(SHOPS_CLEAR);
      resolve("ok");
    });
  },
};

export default {
  actions,
};
