<template>
  <v-row no-gutters align="center" justify="center">
    <v-col cols="12" md="4" lg="4" sm="4" offset-lg="4">
      <v-img :src="logoSrc" contain max-height="45" />
    </v-col>
    <v-col cols="auto" class="ml-auto">
      <v-list dense class="py-0">
        <v-list-item class="px-2">
          <NavBarConfig v-if="userHasRight('superadmin')" />
          <NavBarUser />
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>
<script>
import NavBarConfig from "./NavBar/NavBarConfig";
import NavBarUser from "./NavBar/NavBarUser";
export default {
  name: "NavBar",
  components: {
    NavBarUser,
    NavBarConfig,
  },
  data() {
    return {
      logoSrc: require("../../assets/apv_logo.png"),
      api_version: process.env.VUE_APP_API_VERSION || "v1",
      isDev: process.env.NODE_ENV === "development",
      actions: [
        {
          text: this.$t("main.nav.dashboard"),
          action: "/dashboard",
        },
        {
          text: this.$t("main.nav.shops"),
          action: "/shops",
        },
      ],
    };
  },
  methods: {
    doAction(operation) {
      this.$router.push(operation.action);
    },
    userHasRight: function (profile) {
      return this.$store.getters.user.scope.includes(profile);
    },
    shopDisplay: function () {
      return (
        (this.userHasRight("admin") || this.userHasRight("superadmin")) &&
        (this.$store.getters.user.shopIds === null ||
          this.$store.getters.user.shopIds.length > 1)
      );
    },
    dashboardDisplay: function () {
      return (
        (this.userHasRight("admin") || this.userHasRight("superadmin")) &&
        (this.$store.getters.user.shopIds === null ||
          this.$store.getters.user.shopIds.length > 1)
      );
    },
  },
};
</script>
