<template>
  <!-- TODO: implement actions -->
  <v-menu
    bottom
    offset-y
    rounded="0"
    transition="scale-transition"
    origin="bottom"
  >
    <template v-slot:activator="{ on, menuConfigure }">
      <v-btn text class="mr-1" v-bind="menuConfigure" v-on="on">
        <v-icon>mdi-cog-outline</v-icon>
        <v-icon x-small>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item
        v-for="(operation, index) in actions"
        :key="index"
        @click="doAction(operation)"
      >
        <v-list-item-title>{{
          operation.text || operation.action
        }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: "NavBarConfig",
  data() {
    return {
      actions: [
        {
          text: this.$t("main.nav.configure.clients"),
          action: "#",
        },
        {
          text: this.$t("main.nav.configure.shopgroups"),
          action: "#",
        },
        {
          text: this.$t("main.nav.configure.users"),
          action: "#",
        },
        {
          text: this.$t("main.nav.configure.clientsoauth"),
          action: "#",
        },
      ],
    };
  },
  methods: {
    doAction(operation) {
      if (operation.method) {
        this[operation.action]();
      } else {
        this.$router.push(operation.action);
      }
    },
  },
};
</script>
