<template>
  <!-- color="#fafbfd" -->
  <v-navigation-drawer
    app
    permanent
    class="menu-navigation-drawer"
    :mini-variant="enableNavbar"
    :expand-on-hover="enableNavbar"
  >
    <v-list>
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img :src="config.menuLogoSrc"></v-img>
        </v-list-item-avatar>
        <v-list-item-title class="title text-h4">
          {{ config.menuTitle }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <menu-list :menus="menus" />
    <template>
      <SideBarShop />
    </template>
  </v-navigation-drawer>
</template>

<script>
import { LAYOUT_CONFIG as config } from "@/config";
import SideBarShop from "@/components/main/SideBarShop.vue";
import MenuList from "./MenuList";

export default {
  name: "Navigation",
  components: {
    MenuList,
    SideBarShop,
  },
  props: {
    enableNavbar: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    menus: [],
    config,
  }),
  mounted() {
    // console.log("test", this.$store.getters.shopById)
    // this.menus = this.$store.getters.shopById
  },
};
</script>
<style scoped>
.menu-navigation-drawer {
  z-index: 9999;
}
</style>
