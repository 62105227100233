const routes = {
  v1: {
    login: "/oauth2/token",
    refresh: "/oauth2/token",
    shop_list: "/shops/list",
    shop_detail: "/shops/",
    shop_boxes: "/shops/get-boxes?shop_id=",
    shop_devices: "/shops/:id/devices",
    shop_timeline: "/shops/:id/timeline",
    device_timeline: "/devices/:id/timeline",
    device_boxes: "/devices/get-boxes?device_id=",
  },
  v2: {},
};
export default {
  routes,
};
