import {
  SHOPS_LOAD,
  SHOPS_LOAD_REQUEST,
  SHOPS_CLEAR,
  SHOPS_ADD,
  SHOPS_EDIT,
} from "./enums";

const mutations = {
  [SHOPS_LOAD]: (state, { shopById, shopByName }) => {
    state.shopById = shopById;
    state.shopByName = shopByName;
    state.status = "success";
    state.shopRefreshDate = new Date().getTime();
  },
  [SHOPS_LOAD_REQUEST]: (state) => {
    state.status = "loading";
  },
  [SHOPS_CLEAR]: (state) => {
    state.status = "";
    state.shopById = {};
    state.shopByName = {};
    state.shopRefreshDate = 0;
  },
  [SHOPS_ADD]: (state, { shopId, name, shopData }) => {
    state.shopById[shopId] = shopData;
    state.shopByName[name] = shopData;
    state.status = "success";
    state.shopRefreshDate = new Date().getTime();
  },
  [SHOPS_EDIT]: (state, { shopId, name, shopData }) => {
    state.shopById[shopId] = shopData;
    state.shopByName[name] = shopData;
    state.status = "success";
    state.shopRefreshDate = new Date().getTime();
  },
};

export default {
  mutations,
};
