<template>
  <div class="v-application--wrap">
    <!-- Navigation-->
    <navigation :enableNavbar="enableNavbar" v-if="isShopScoped()" />

    <v-app-bar
      app
      dense
      :color="config.appBarColor"
      class="app-bar"
      elevation="1"
    >
      <v-app-bar-nav-icon
        small
        @click.stop="enableNavbar = !enableNavbar"
        v-if="isShopScoped()"
      >
        <v-icon>{{ toggleNavIcon }}</v-icon>
      </v-app-bar-nav-icon>

      <nav-bar v-if="isAuthenticated()" />
    </v-app-bar>

    <!--Main Content-->
    <v-main class="main-content">
      <v-container fluid class="height-100pc">
        <transition name="slide-left" appear>
          <router-view class="main-router-view" :key="routerKey" />
        </transition>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { LAYOUT_CONFIG as config } from "@/config";
import NavBar from "@/components/main/NavBar.vue";
import Navigation from "@/views/layout/components/Navigation";

export default {
  name: "Layout",
  components: {
    NavBar,
    Navigation,
  },
  data: () => ({
    config,
    enableNavbar: true,
    enableSearch: false,
  }),
  computed: {
    toggleNavIcon() {
      return this.enableNavbar
        ? "mdi-format-indent-increase"
        : "mdi-format-indent-decrease";
    },
    routerKey() {
      return this.$route.meta.id;
    },
  },
  methods: {
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated;
    },
    isShopScoped: function () {
      return this.$route.meta.shopScoped === true;
    },
  },
};
</script>
<style>
.v-app-bar__nav-icon {
  position: absolute !important;
  left: 5px !important;
  margin: 0 !important;
}

.v-toolbar__content > div {
  width: 100%;
}
.navbar {
  padding: 0;
}
</style>
