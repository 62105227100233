import _state from "./state";
import _getters from "./getters";
import _actions from "./actions";
import _mutations from "./mutations";
import mutations_dev from "./mutations_dev";

let mutations = _mutations.mutations;
let actions = _actions.actions;
let getters = _getters.getters;
let state = _state.state;

if (process.env.NODE_ENV === "development") {
  mutations = Object.assign({}, mutations, mutations_dev.mutations);
}

export default {
  state,
  getters,
  actions,
  mutations,
};
