<template>
  <v-app>
    <router-view />
    <div style="height: 20px" class="bg-success"></div>
  </v-app>
</template>

<style></style>
<script>
export default {};
</script>
