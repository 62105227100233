/**
 * Layout Configuration
 * @type {{appTile: string}}
 */
export const LAYOUT_CONFIG = {
  menuLogoSrc: require("./assets/apv_logo.png"),
  menuTitle: "Shops",
  menuColor: "#fff",
  menuTextColor: "#204e97",

  appBarColor: "#fff",
  appBarTitle: "MediaTouch",
};
