import Vue from "vue";
import VueRouter from "vue-router";
import DevAuthStore from "@/views/dev/DevAuthStore.vue";
import store from "@/store/index.js";
import { AUTH_REFRESH } from "@/store/modules/auth/enums";
import Demo from "@/views/dev/Demo.vue";
import Layout from "@/views/layout/index.vue";

Vue.use(VueRouter);

let routes = [
  {
    path: "/dev/demo",
    name: "Demo",
    component: Demo,
    meta: {
      devOnly: true,
      shopScoped: true,
      scopePrefix: "/dev/demo/",
    },
  },
  {
    path: "/dev/demo/:id",
    name: "DevDemo",
    component: Demo,
    meta: {
      devOnly: true,
      shopScoped: true,
      scopePrefix: "/dev/demo/",
      scopeSuffix: "",
    },
  },
  {
    path: "/dev/store/auth",
    name: "DevAuthStore",
    component: DevAuthStore,
    meta: {
      devOnly: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/main/Login.vue"),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/home",
    redirect: () => {
      if (!store.getters.isAuthenticated) {
        return { path: "/login" };
      }
      if (
        store.getters.user.shopIds === null ||
        store.getters.user.shopIds.length == 0 ||
        store.getters.user.shopIds.length > 1
      ) {
        return { path: "/shops" };
      } else {
        return { path: "/shops/" + store.getters.user.shopIds[0] };
      }
    },
  },
  {
    path: "/home",
    component: Layout,
    children: [
      {
        path: "/shops",
        name: "shops",
        component: () => import("@/views/shops/ShopList.vue"),
        meta: {},
      },
      {
        path: "/shops/:id",
        component: () => import("@/views/shops/Shop.vue"),
        children: [
          {
            path: "",
            name: "ShopMain",
            component: () => import("@/views/shops/ShopTimeline.vue"),
            meta: { shopScoped: true },
          },
          {
            path: "edit",
            name: "ShopEdit",
            component: () => import("@/views/shops/ShopEdit.vue"),
            meta: {
              shopScoped: true,
              scopePrefix: "/shops/",
              scopeSuffix: "/edit",
              shopBreadcrumb: true,
            },
          },
          {
            path: "timeline",
            name: "ShopTimeline",
            component: () => import("@/views/shops/ShopTimeline.vue"),
            meta: {
              shopScoped: true,
              scopePrefix: "/shops/",
              scopeSuffix: "/timeline",
            },
          },
          {
            path: "dashboard",
            name: "ShopDashboard",
            component: () => import("@/views/shops/ShopDashboard.vue"),
            meta: {
              shopScoped: true,
              scopePrefix: "/shops/",
              scopeSuffix: "/dashboard",
            },
          },
          {
            path: "devices",
            name: "ShopDevices",
            component: () => import("@/views/shops/ShopDashboard.vue"),
            meta: {
              shopScoped: true,
              scopePrefix: "/shops/",
              scopeSuffix: "/devices",
              deviceScoped: true,
            },
          },
        ],
      },
    ],
  },
  { path: "/", redirect: "/home", name: "Home" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // dev routes are not authenticated
  if (to.matched.some((record) => record.meta.devOnly)) {
    if (process.env.NODE_ENV !== "development") {
      next(false);
    } else {
      next();
    }
  } else {
    if (store.getters.isAuthenticated) {
      next();
    } else {
      if (to.matched.some((record) => record.meta.requireAuth === false)) {
        next();
      } else {
        if (store.getters.isRefreshable) {
          store
            .dispatch(AUTH_REFRESH)
            .then(() => next())
            .catch(() => next("/login"));
        } else {
          next("/login");
        }
      }
    }
  }
});

export default router;
