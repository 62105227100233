import { SHOPS_LOAD, SHOPS_LOAD_REQUEST, SHOPS_ADD, SHOPS_EDIT } from "./enums";
import Vue from "vue";
import axios from "axios";
// import request from "@/utils/request"

let actions = {
  [SHOPS_LOAD]: ({ commit }, shops) => {
    console.log("loading shops", shops);
    return new Promise((resolve, reject) => {
      const http = Vue.prototype.$http;
      commit(SHOPS_LOAD_REQUEST);
      if (shops === null || shops.length == 0) {
        let url =
          Vue.prototype.$api.routes[process.env.VUE_APP_API_VERSION || "v1"]
            .shop_list;
        console.log(process.env.VUE_APP_API_BASE_URL + url);

        http({ url: process.env.VUE_APP_API_BASE_URL + url })
          .then((resp) => {
            var shopById = {};
            var shopByName = {};
            for (var i in resp.data) {
              shopById[resp.data[i]._id] = resp.data[i];
              shopByName[resp.data[i].title] = resp.data[i];
            }
            commit(SHOPS_LOAD, { shopById, shopByName });
            console.log("test", resp);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
        // list shop
      } else {
        for (var shop in shops) {
          let url =
            Vue.prototype.$api.routes[process.env.VUE_APP_API_VERSION || "v1"]
              .shop_detail + shops[shop];
          console.log(process.env.VUE_APP_API_BASE_URL + url);
          http({ url: process.env.VUE_APP_API_BASE_URL + url })
            .then((resp) => {
              const shopId = shops[shop];
              const name = resp.data.title;
              const shopData = resp.data;
              commit(SHOPS_ADD, { shopId, name, shopData });
              resolve(resp);
            })
            .catch((err) => {
              reject(err);
            });
        }
      }
    });
  },
  [SHOPS_EDIT]: ({ commit }, shop) => {
    console.log("Begin action" + SHOPS_EDIT, shop);
    return new Promise((resolve, reject) => {
      commit(SHOPS_LOAD_REQUEST);
      if (shop !== null) {
        let url =
          Vue.prototype.$api.routes[process.env.VUE_APP_API_VERSION || "v1"]
            .shop_detail;

        axios({
          url: process.env.VUE_APP_API_BASE_URL + url,
          data: shop,
          method: "PUT",
        })
          .then((resp) => {
            const shopId = shop._id;
            const name = resp.data.title;
            const shopData = resp.data;
            commit(SHOPS_EDIT, { shopId, name, shopData });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  },
};

export default {
  actions,
};
