<template>
  <b-nav
    vertical
    class="w-100 navbar-nav navbar-expand"
    type="dark"
    variant="success"
    style="height: 90vh"
  >
    <b-nav-item disabled>{{ $t("main.sideshop.title") }}</b-nav-item>
    <b-nav-item
      v-for="shop in shops()"
      :key="shop.id"
      :href="$route.meta.scopePrefix + shop.id + $route.meta.scopeSuffix"
      :active="$route.params.id == shop.id"
      >{{ shop.title }}</b-nav-item
    >
  </b-nav>
</template>
<script>
export default {
  name: "SideBarShop",
  methods: {
    shopSel: function (shop) {
      this.$router.push(
        this.$route.meta.scopePrefix + shop + this.$route.meta.scopeSuffix
      );
    },
    shops: function () {
      if (this.$store.getters.shopStatus !== "success") {
        const shopIds = this.$store.getters.user.shopIds;
        this.$store.dispatch("SHOPS_LOAD", shopIds).then(() => {
          var dict = this.$store.getters.shopByName;
          var sorted = [];
          for (var key in dict) {
            sorted[sorted.length] = key;
          }
          sorted.sort();

          var tempDict = {};
          for (var i = 0; i < sorted.length; i++) {
            tempDict[sorted[i]] = dict[sorted[i]];
          }
          return tempDict;
        });
      }
      var dict = this.$store.getters.shopByName;
      var sorted = [];
      for (var key in dict) {
        sorted[sorted.length] = key;
      }
      sorted.sort();

      var tempDict = {};
      for (var i = 0; i < sorted.length; i++) {
        tempDict[sorted[i]] = dict[sorted[i]];
      }
      return tempDict;
    },
  },
};
</script>
