import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/auth/auth";
import shops from "@/store/modules/shops/shops";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["auth", "shops"],
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    shops,
  },
  plugins: [vuexLocal.plugin],
});
