const getters = {
  isAuthenticated: (state) =>
    !!state.accessToken && state.accessTokenExpiresAt > new Date().getTime(),
  isRefreshable: (state) =>
    !!state.refreshToken && state.refreshTokenExpiresAt > new Date().getTime(),
  authStatus: (state) => state.status,
  user: (state) => state.user,
  accessToken: (state) => state.accessToken,
  scopes: (state) => state.user.scope,
};

export default {
  getters,
};
