import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import api from "./api";
import FlagIcon from "vue-flag-icon";
import Axios from "axios";
import vuetify from "@/plugins/vuetify";

Vue.config.productionTip = false;

Vue.prototype.$api = api;
Vue.prototype.$http = Axios;

const token = localStorage.getItem("user-token");

if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] =
    "Bearer " + token;
}
Vue.use(FlagIcon);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
