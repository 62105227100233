const state = {
  accessToken: "",
  accessTokenExpiresAt: 0,
  refreshToken: "",
  refreshTokenExpiresAt: 0,
  scope: "",
  client: {
    id: "",
    clientId: "",
    scope: "",
  },
  user: {
    id: "",
    email: "",
    fullname: "",
    company: "",
    shopIds: ["voluntarily not empty nor null"],
    scope: [],
  },
  status: "",
  tenant: "",
};

export default {
  state,
};
