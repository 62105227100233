const getters = {
  shopById: (state) => state.shopById,
  shopByName: (state) => state.shopByName,
  shopStatus: (state) => state.status,
  shopRefreshDate: (state) => state.shopRefreshDate,
};

export default {
  getters,
};
