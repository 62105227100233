<template>
  <div>
    <b-button title="alter auth" @click="alterAuth" />
    <b-button title="load shops" @click="loadShops"> Load Shops </b-button>
  </div>
</template>

<script>
export default {
  methods: {
    alterAuth: function () {
      this.$store.commit("DEV_TOKEN_EXPIRY", 0);
    },
    loadShops: function () {
      this.$store.dispatch("SHOPS_LOAD", null);
    },
  },
};
</script>
