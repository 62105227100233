<template>
  <!-- implement actions -->
  <v-menu
    bottom
    offset-y
    rounded="0"
    transition="scale-transition"
    origin="bottom"
  >
    <template v-slot:activator="{ on, menuUser }">
      <v-btn text class="mr-1" v-bind="menuUser" v-on="on">
        {{ user() }} <v-icon x-small>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item key="lang">
        <v-list-item-content @click="changeLocale('fr')">
          <flag iso="fr"
        /></v-list-item-content>
        <v-list-item-content @click="changeLocale('en')">
          <flag iso="us"
        /></v-list-item-content>
      </v-list-item>
      <v-list-item
        v-for="(operation, index) in actions"
        :key="index"
        @click="doAction(operation)"
      >
        <v-list-item-title class="text-center">{{
          operation.text || operation.action
        }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import { AUTH_LOGOUT } from "@/store/modules/auth/enums";
export default {
  name: "NavBarUser",
  data() {
    return {
      api_version: process.env.VUE_APP_API_VERSION || "v1",
      isDev: process.env.NODE_ENV === "development",
      actions: [
        {
          text: this.$t("main.nav.user.logout"),
          action: "logout",
          method: true,
        },
      ],
    };
  },
  methods: {
    doAction(operation) {
      if (operation.method) {
        this[operation.action]();
      } else {
        this.$router.push(operation.action);
      }
    },
    logout: function () {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/login"));
    },
    changeLocale: function (locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("i18n", locale);
    },
    user: function () {
      return this.$store.getters.user.fullname;
    },
  },
};
</script>
